define("discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/anonymous-report", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse-common/lib/get-url", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.show}}
    <DModal
      @class="flag-modal-body"
      @title={{i18n "flagging.title"}}
      @closeModal={{fn (mut this.show) false}}
      class="anonymous-report-modal"
    >
      <:body>
        <BFlagReport @post={{@model.flagModel}} @disableClick="true" />
      </:body>
      <:footer>
        <DButton
          class="btn-primary flag-modal__create-flag"
          @action={{this.navigate}}
          @title="flagging.submit_tooltip"
          @icon="flag"
          @label="flagging_topic.action"
        />
      </:footer>
    </DModal>
  {{/if}}
  */
  {
    "id": "N/w22umI",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"anonymous-report-modal\"]],[[\"@class\",\"@title\",\"@closeModal\"],[\"flag-modal-body\",[28,[37,2],[\"flagging.title\"],null],[28,[37,3],[[28,[37,4],[[30,0,[\"show\"]]],null],false],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@post\",\"@disableClick\"],[[30,1,[\"flagModel\"]],\"true\"]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[39,6],[[24,0,\"btn-primary flag-modal__create-flag\"]],[[\"@action\",\"@title\",\"@icon\",\"@label\"],[[30,0,[\"navigate\"]],\"flagging.submit_tooltip\",\"flag\",\"flagging_topic.action\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"if\",\"d-modal\",\"i18n\",\"fn\",\"mut\",\"b-flag-report\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/anonymous-report.hbs",
    "isStrictMode": false
  });
  let AnonymousReport = _exports.default = (_class = class AnonymousReport extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "show", _descriptor, this);
    }
    get href() {
      return (0, _getUrl.getAbsoluteURL)(`/dsa/report.json?post_id=${this.model.flagModel.id}`);
    }
    navigate(e) {
      window.open(this.href, "_blank", "noopener=true");
      this.show = false;
      e.preventDefault();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "show", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "navigate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "navigate"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnonymousReport);
});