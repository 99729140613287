define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-account-link", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="b-account-link">
    <div class="control-group pref-email">
      <label class="control-label">{{i18n "blizzard_email.title"}}</label>
      <div class="controls">
        <a
          href="{{this.siteSettings.battle_net_base_url}}/account/"
          target="_blank"
          class="battle-net-link"
          rel="noopener noreferrer"
        >
          {{i18n "blizzard_email.link"}}
          {{d-icon "external-link-alt"}}
        </a>
      </div>
      <div class="instructions">
        {{i18n "blizzard_email.instructions"}}
      </div>
    </div>
  </div>
  */
  {
    "id": "F7I8gP2v",
    "block": "[[[10,0],[14,0,\"b-account-link\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group pref-email\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"blizzard_email.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,0,[\"siteSettings\",\"battle_net_base_url\"]],\"/account/\"]]],[14,\"target\",\"_blank\"],[14,0,\"battle-net-link\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"blizzard_email.link\"],null]],[1,\"\\n        \"],[1,[28,[35,1],[\"external-link-alt\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"blizzard_email.instructions\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-account-link.hbs",
    "isStrictMode": false
  });
  let BAccountLink = _exports.default = (_class = class BAccountLink extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BAccountLink);
});