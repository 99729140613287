define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-welcome", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <header class="b-welcome {{@welcome-location}}">
    {{#if this.viewingHomepage}}
      <div class="b-welcome-banner-wrap">
        <DSection @bodyClass="blizzard-homepage" class="b-welcome-banner">
          <div
            class="b-welcome-banner-contents wrap
              {{unless this.welcomeText 'no-welcome-text'}}"
          >
            <div class="game-logo"></div>
  
            {{#if this.welcomeText}}
              <h1>{{this.welcomeText}}</h1>
            {{/if}}
          </div>
        </DSection>
      </div>
    {{/if}}
  </header>
  */
  {
    "id": "w6M0LVYa",
    "block": "[[[10,\"header\"],[15,0,[29,[\"b-welcome \",[30,1]]]],[12],[1,\"\\n\"],[41,[30,0,[\"viewingHomepage\"]],[[[1,\"    \"],[10,0],[14,0,\"b-welcome-banner-wrap\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"b-welcome-banner\"]],[[\"@bodyClass\"],[\"blizzard-homepage\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[29,[\"b-welcome-banner-contents wrap\\n            \",[52,[51,[30,0,[\"welcomeText\"]]],\"no-welcome-text\"]]]],[12],[1,\"\\n          \"],[10,0],[14,0,\"game-logo\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"welcomeText\"]],[[[1,\"            \"],[10,\"h1\"],[12],[1,[30,0,[\"welcomeText\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@welcome-location\"],false,[\"if\",\"d-section\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-welcome.hbs",
    "isStrictMode": false
  });
  let BWelcome = _exports.default = (_class = class BWelcome extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
    get viewingHomepage() {
      return this.args.currentPath === "discovery.categories";
    }
    get welcomeText() {
      let franchise = this.site.get("blizzard_franchise");
      return _I18n.default.t(`${franchise}.welcome`, {
        defaultValue: ""
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BWelcome);
});