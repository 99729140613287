define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-refresh-profile-button", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{this.updateProfile}}
    @icon={{this.buttonIcon}}
    @label="admin.blizzard.refresh_profile"
    @disabled={{this.loading}}
    class="btn-danger update-profile-button"
  />
  */
  {
    "id": "O21VcuBd",
    "block": "[[[8,[39,0],[[24,0,\"btn-danger update-profile-button\"]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"updateProfile\"]],[30,0,[\"buttonIcon\"]],\"admin.blizzard.refresh_profile\",[30,0,[\"loading\"]]]],null]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-refresh-profile-button.hbs",
    "isStrictMode": false
  });
  let BRefreshProfileButton = _exports.default = (_class = class BRefreshProfileButton extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "loading", _descriptor, this);
      _initializerDefineProperty(this, "done", _descriptor2, this);
    }
    get buttonIcon() {
      if (this.loading) {
        return "spinner";
      } else if (this.done) {
        return "blizzard-check";
      }
      return "sync";
    }
    updateProfile() {
      const user = this.args.model;
      const path = `/blizzard/refresh_profile/${user.id}`;
      this.loading = true;
      return (0, _ajax.ajax)(path, {
        type: "POST"
      }).then(() => {
        this.loading = false;
        this.done = true;
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
        this.done = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "done", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateProfile", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProfile"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BRefreshProfileButton);
});