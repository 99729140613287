define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-realms-list", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.filteredRealms}}
    <div class="b-realms-list">
      {{! template-lint-disable no-invalid-interactive }}
      <div class="realms-title" {{on "click" this.toggleExpanded}}>
        <div class="realms-icon"></div>
        <div class="realms-title-text">{{i18n (concat "realms." @filter)}}</div>
        {{d-icon this.caretIcon class="caret-icon"}}
      </div>
  
      {{#if this.isExpanded}}
        <div class="filtered-realms {{@filter}}">
          {{#each this.filteredRealms as |r|}}
            <div class="filtered-realm">
              <a class="b-realm" href={{r.url}}>
                <div class="realm-icon"></div>
                <div class="realm-name">{{realm-name r}}</div>
              </a>
            </div>
          {{/each}}
        </div>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "/i43ysmc",
    "block": "[[[41,[30,0,[\"filteredRealms\"]],[[[1,\"  \"],[10,0],[14,0,\"b-realms-list\"],[12],[1,\"\\n\"],[1,\"    \"],[11,0],[24,0,\"realms-title\"],[4,[38,1],[\"click\",[30,0,[\"toggleExpanded\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"realms-icon\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"realms-title-text\"],[12],[1,[28,[35,2],[[28,[37,3],[\"realms.\",[30,1]],null]],null]],[13],[1,\"\\n      \"],[1,[28,[35,4],[[30,0,[\"caretIcon\"]]],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isExpanded\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"filtered-realms \",[30,1]]]],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"filteredRealms\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"filtered-realm\"],[12],[1,\"\\n            \"],[10,3],[14,0,\"b-realm\"],[15,6,[30,2,[\"url\"]]],[12],[1,\"\\n              \"],[10,0],[14,0,\"realm-icon\"],[12],[13],[1,\"\\n              \"],[10,0],[14,0,\"realm-name\"],[12],[1,[28,[35,7],[[30,2]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@filter\",\"r\"],false,[\"if\",\"on\",\"i18n\",\"concat\",\"d-icon\",\"each\",\"-track-array\",\"realm-name\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-realms-list.hbs",
    "isStrictMode": false
  });
  let BRealmsList = _exports.default = (_class = class BRealmsList extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "isExpanded", _descriptor2, this);
    }
    get caretIcon() {
      return this.isExpanded ? "caret-up" : "caret-down";
    }
    get filteredRealms() {
      let list = this.args.categories.filter(c => c.get("category_metadata.is_realm"));
      if (this.args.filter === "classic") {
        list = list.filter(c => c.get("category_metadata.is_realm") === "classic");
      } else if (this.args.filter === "yours") {
        // TODO: how to get current user in glimmer component
        let yours = this.currentUser && this.currentUser.get("realms_list") || [];
        list = list.filter(c => yours.indexOf(c.get("name")) !== -1);
      } else {
        list = list.filter(c => c.get("category_metadata.is_realm") !== "classic");
      }
      return list.sortBy("name");
    }
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleExpanded", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleExpanded"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BRealmsList);
});