define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-user-profile-avatar", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canChangeAvatar}}
    {{! template-lint-disable no-invalid-interactive }}
    <div
      class="user-profile-avatar selectable"
      {{action "showAvatarSelector" @user}}
      title={{i18n "user.change_avatar.label"}}
    >
      {{bound-avatar @user "huge"}}
      {{d-icon "blizzard-account"}}
      <UserAvatarFlair @user={{@user}} />
      <PluginOutlet
        @name="user-profile-avatar-flair"
        @connectorTagName="div"
        @outletArgs={{hash model=@user}}
        @tagName="div"
      />
    </div>
  {{else if this.canChangeCharacter}}
    {{! template-lint-disable no-invalid-interactive }}
    <div
      class="user-profile-avatar selectable"
      {{action "showCharacterSelector" @user}}
      title={{i18n "user.change_character.label"}}
    >
      {{bound-avatar @user "huge"}}
      {{d-icon "blizzard-account"}}
      <UserAvatarFlair @user={{@user}} />
      <PluginOutlet
        @name="user-profile-avatar-flair"
        @connectorTagName="div"
        @outletArgs={{hash model=@user}}
        @tagName="div"
      />
    </div>
  {{else}}
    <div class="user-profile-avatar">
      {{bound-avatar @user "huge"}}
      <UserAvatarFlair @user={{@user}} />
      <PluginOutlet
        @name="user-profile-avatar-flair"
        @connectorTagName="div"
        @outletArgs={{hash model=@user}}
        @tagName="div"
      />
    </div>
  {{/if}}
  */
  {
    "id": "5Vzz+avW",
    "block": "[[[41,[30,0,[\"canChangeAvatar\"]],[[[1,\"  \"],[11,0],[24,0,\"user-profile-avatar selectable\"],[16,\"title\",[28,[37,1],[\"user.change_avatar.label\"],null]],[4,[38,2],[[30,0],\"showAvatarSelector\",[30,1]],null],[12],[1,\"\\n    \"],[1,[28,[35,3],[[30,1],\"huge\"],null]],[1,\"\\n    \"],[1,[28,[35,4],[\"blizzard-account\"],null]],[1,\"\\n    \"],[8,[39,5],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\",\"@tagName\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,7],null,[[\"model\"],[[30,1]]]],\"div\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"canChangeCharacter\"]],[[[1,\"  \"],[11,0],[24,0,\"user-profile-avatar selectable\"],[16,\"title\",[28,[37,1],[\"user.change_character.label\"],null]],[4,[38,2],[[30,0],\"showCharacterSelector\",[30,1]],null],[12],[1,\"\\n    \"],[1,[28,[35,3],[[30,1],\"huge\"],null]],[1,\"\\n    \"],[1,[28,[35,4],[\"blizzard-account\"],null]],[1,\"\\n    \"],[8,[39,5],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\",\"@tagName\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,7],null,[[\"model\"],[[30,1]]]],\"div\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"user-profile-avatar\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[30,1],\"huge\"],null]],[1,\"\\n    \"],[8,[39,5],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\",\"@tagName\"],[\"user-profile-avatar-flair\",\"div\",[28,[37,7],null,[[\"model\"],[[30,1]]]],\"div\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"@user\"],false,[\"if\",\"i18n\",\"action\",\"bound-avatar\",\"d-icon\",\"user-avatar-flair\",\"plugin-outlet\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-user-profile-avatar.hbs",
    "isStrictMode": false
  });
  let BUserProfileAvatar = _exports.default = (_class = class BUserProfileAvatar extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    }
    get canChangeAvatar() {
      return this.currentUser && this.currentUser.get("staff") && this.args.user === this.currentUser || this.siteSettings.selectable_avatars_mode !== "disabled" && this.currentUser && (this.currentUser.get("staff") || this.args.user === this.currentUser);
    }
    get canChangeCharacter() {
      return this.siteSettings.selectable_characters_enabled && this.currentUser === this.args.user;
    }
    showAvatarSelector(user) {
      this.appEvents.trigger("dom:clean", "header");
      this.appEvents.trigger("show-avatar-select", user);
      return false;
    }
    showCharacterSelector(user) {
      this.appEvents.trigger("dom:clean", "header");
      this.appEvents.trigger("show-character-select", user);
      return false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showAvatarSelector", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showAvatarSelector"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCharacterSelector", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showCharacterSelector"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BUserProfileAvatar);
});