define("discourse/plugins/discourse-blizzard-plugin/discourse/raw-templates/mobile/list/topic-list-item", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <div class=\"creator\">\n          <a\n            href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.creator.path", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 18,
            "column": 18
          },
          "end": {
            "line": 18,
            "column": 40
          }
        }
      })) + "\"\n            data-user-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.creator.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 19,
            "column": 28
          },
          "end": {
            "line": 19,
            "column": 54
          }
        }
      })) + "\"\n          >\n            " + alias2((lookupProperty(helpers, "format-username") || depth0 && lookupProperty(depth0, "format-username") || container.hooks.helperMissing).call(alias1, "topic.creator.username", {
        "name": "format-username",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 21,
            "column": 12
          },
          "end": {
            "line": 21,
            "column": 54
          }
        }
      })) + "\n          </a>\n        </div>\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "topic.category", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(4, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 25,
            "column": 8
          },
          "end": {
            "line": 30,
            "column": 15
          }
        }
      })) != null ? stack1 : "";
    },
    "4": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "          <div class=\"b-category-small\">\n            <img alt=\"\" src=" + alias2((lookupProperty(helpers, "b-category-icon") || depth0 && lookupProperty(depth0, "b-category-icon") || container.hooks.helperMissing).call(alias1, "topic.category", {
        "name": "b-category-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 27,
            "column": 28
          },
          "end": {
            "line": 27,
            "column": 62
          }
        }
      })) + ">\n            <span class=\"b-category-label\">" + alias2(lookupProperty(helpers, "get").call(alias1, "topic.category.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 43
          },
          "end": {
            "line": 28,
            "column": 66
          }
        }
      })) + "</span>\n          </div>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<td>\n  <div class=\"latest-topic-list-item\">\n    <div class=\"b-topic-line title-and-reply-count\">\n      <div class=\"b-topic-link main-link\">\n        " + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-before-status"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 8
          },
          "end": {
            "line": 5,
            "column": 61
          }
        }
      })) + "\n        " + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-status", {
        "name": "raw",
        "hash": {
          "topic": "topic"
        },
        "hashTypes": {
          "topic": "PathExpression"
        },
        "hashContexts": {
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 8
          },
          "end": {
            "line": 6,
            "column": 42
          }
        }
      })) + "\n        " + alias3((lookupProperty(helpers, "topic-link") || depth0 && lookupProperty(depth0, "topic-link") || alias2).call(alias1, "topic", {
        "name": "topic-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 8
          },
          "end": {
            "line": 7,
            "column": 28
          }
        }
      })) + "\n      </div>\n\n      " + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "list.posts-count-column", {
        "name": "raw",
        "hash": {
          "tagName": "div",
          "topic": "topic"
        },
        "hashTypes": {
          "tagName": "StringLiteral",
          "topic": "PathExpression"
        },
        "hashContexts": {
          "tagName": depth0,
          "topic": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 10,
            "column": 6
          },
          "end": {
            "line": 10,
            "column": 65
          }
        }
      })) + "\n    </div>\n\n    <!-- Parent element for category name and the age of the post -->\n    <div class=\"b-topic-line category-and-age\">\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "hideCategory", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.program(3, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 15,
            "column": 6
          },
          "end": {
            "line": 31,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "\n      <a\n        href=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.lastPostUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 34,
            "column": 14
          },
          "end": {
            "line": 34,
            "column": 35
          }
        }
      })) + "\"\n        class=\"b-age\"\n        title=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "topic.bumpedAtTitle", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 36,
            "column": 15
          },
          "end": {
            "line": 36,
            "column": 38
          }
        }
      })) + "\"\n      >\n        " + alias3((lookupProperty(helpers, "format-date") || depth0 && lookupProperty(depth0, "format-date") || alias2).call(alias1, "topic.bumpedAt", {
        "name": "format-date",
        "hash": {
          "noTitle": "true",
          "format": "tiny"
        },
        "hashTypes": {
          "noTitle": "StringLiteral",
          "format": "StringLiteral"
        },
        "hashContexts": {
          "noTitle": depth0,
          "format": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 38,
            "column": 8
          },
          "end": {
            "line": 38,
            "column": 67
          }
        }
      })) + "\n      </a>\n    </div>\n  </div>\n</td>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/mobile/list/topic-list-item", template, {
    core: true
  });
  var _default = _exports.default = template;
});