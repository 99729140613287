define("discourse/plugins/discourse-blizzard-plugin/initializers/customize-widgets", ["exports", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/anonymous-report"], function (_exports, _tracking, _object, _computed, _ajax, _pluginApi, _utilities, _getUrl, _decorators, _I18n, _anonymousReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "customize-widgets",
    after: "inject-objects",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const modal = container.lookup("service:modal");
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        var _obj, _init, _dec, _obj2, _dec2, _obj3;
        // Log out of battle.net after we've destroyed our session.
        api.modifyClass("route:application", {
          pluginId: "blizzard",
          actions: {
            logout() {
              let backTo = encodeURIComponent(document.location.href);
              this.currentUser.destroySession().then(() => {
                window.location.href = `${this.site.bnet_logout_url}?ref=${backTo}`;
              });
            }
          }
        });
        api.modifyClass("component:modal/avatar-selector", (_obj = {
          pluginId: "blizzard",
          fetchedAvatars: null,
          get selectableAvatars() {
            if (this.fetchedAvatars) {
              return this.fetchedAvatars;
            } else if (siteSettings.selectable_avatars_mode !== "disabled") {
              (0, _ajax.ajax)("/site/selectable-avatars.json").then(result => this.fetchedAvatars = result);
            } else {
              return [];
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "fetchedAvatars", [_tracking.tracked], (_init = Object.getOwnPropertyDescriptor(_obj, "fetchedAvatars"), _init = _init ? _init.value : undefined, {
          enumerable: true,
          configurable: true,
          writable: true,
          initializer: function () {
            return _init;
          }
        }), _obj), _applyDecoratedDescriptor(_obj, "selectableAvatars", [_tracking.cached], Object.getOwnPropertyDescriptor(_obj, "selectableAvatars"), _obj)), _obj));

        // Client side display of usernames: strip battletag
        api.formatUsername(username => username.replace(/\-.*$/, ""));

        // Show the footer on the error page
        api.modifyClass("route:unknown", {
          pluginId: "blizzard",
          showFooter: true
        });
        if (siteSettings.blizzard_realms_enabled) {
          // Exclude realms from bread crumbs
          api.modifyClass("component:bread-crumbs", {
            pluginId: "blizzard",
            parentCategories: (0, _computed.filter)("categories", function (c) {
              let category = this.get("category");
              return !c.get("parentCategory") && (c === category || !c.get("category_metadata.is_realm"));
            })
          });
        }

        // Always show topic in header when available
        api.modifyClass("component:discourse-topic", {
          pluginId: "blizzard",
          shouldShowTopicInHeader() {
            return true;
          }
        });

        // Always use a down arrow for the category dropdown
        api.modifyClass("component:category-drop", {
          pluginId: "blizzard",
          caretDownIcon: "caret-down"
        });

        // Dynamically change the component to render the categories
        api.modifyClass("component:categories-only", (_dec = (0, _decorators.default)("siteSettings.blizzard_group_categories"), (_obj2 = {
          pluginId: "blizzard",
          categoryListComponent(groupCategories) {
            return groupCategories ? "b-category-groups" : "b-categories-columned";
          }
        }, (_applyDecoratedDescriptor(_obj2, "categoryListComponent", [_dec], Object.getOwnPropertyDescriptor(_obj2, "categoryListComponent"), _obj2)), _obj2)));

        // Exclude pinned topics from the topic list (they are separated)
        api.modifyClass("component:topic-list", (_dec2 = (0, _decorators.default)("topics", "discoveryList", "topics.length"), (_obj3 = {
          pluginId: "blizzard",
          filteredTopics(topics, discoveryList) {
            return discoveryList ? topics.filter(t => !t.get("pinned")) : topics;
          }
        }, (_applyDecoratedDescriptor(_obj3, "filteredTopics", [_dec2], Object.getOwnPropertyDescriptor(_obj3, "filteredTopics"), _obj3)), _obj3)));

        // If the user clicks on the topic list item rows, navigate to the topic
        const unhandledClick = {
          unhandledRowClick(e, topic) {
            if (e.target.tagName !== "INPUT" && e.target.className !== "star" && e.target.tagName !== "A" && !e.target.classList.contains("first-tracked-post")) {
              return this.navigateToTopic(topic);
            }
          }
        };
        api.modifyClass("component:topic-list-item", {
          pluginId: "blizzard",
          ...unhandledClick
        });
        api.modifyClass("component:latest-topic-list-item", {
          pluginId: "blizzard",
          ...unhandledClick
        });
        api.modifyClass("component:site-header", {
          pluginId: "blizzard",
          docAt: 48
        });
        if (siteSettings.selectable_characters_enabled) {
          api.modifyClass("component:user-card-contents", {
            pluginId: "blizzard",
            classNameBindings: ["selectableCharacter"],
            selectableCharacter: (0, _object.computed)("user.profile_hidden", function () {
              if (!this.get("user.profile_hidden")) {
                return "selectable-characters-enabled";
              }
            })
          });
        }

        // Blizzard always shows the topic title, even if not scrolled
        api.modifyClass("component:discourse-topic", {
          pluginId: "blizzard",
          showTopicInHeader: () => true
        });
        api.modifyClass("controller:user", {
          pluginId: "blizzard",
          canCheckEmails: false,
          canExpandProfile: false,
          collapsedInfo: (0, _computed.alias)("model.profile_hidden")
        });
        api.modifyClass("controller:preferences/account", {
          pluginId: "blizzard",
          canCheckEmails: false
        });

        // Disable the user's ability to download their posts
        api.modifyClass("controller:user-activity", {
          pluginId: "blizzard",
          canDownloadPosts: false
        });

        // Don't render the header buttons
        api.reopenWidget("header-buttons", {
          html() {}
        });
        api.reopenWidget("header-contents", {
          template: function (attrs, state) {
            var _r = [];
            _r.push("\n");
            if (this.site.desktopView) {
              if (attrs.sidebarEnabled) {
                _r.push("              ");
                _r.push(this.attach("sidebar-toggle", attrs));
                _r.push("\n");
              }
            }
            _r.push("          ");
            var _a0 = [];
            _a0.push(this.attrs.contents());
            _r.push(virtualDom.h('div', {
              "className": "panel clearfix",
              "attributes": {
                "role": "navigation"
              }
            }, _a0));
            _r.push("\n          ");
            _r.push(this.attach("b-nav-breadcrumbs", attrs, undefined, undefined));
            _r.push("\n        ");
            return _r;
          }
        });
        if (siteSettings.selectable_characters_enabled) {
          api.addPostTransformCallback(t => {
            let ucf = t.userCustomFields;
            if (ucf) {
              t.character = {
                name: (0, _utilities.formatUsername)(t.username),
                guild: ucf.guild,
                achievement_points: ucf.achievement_points,
                level: ucf.level,
                class: ucf.class,
                race: ucf.race,
                player_class_enum: ucf.player_class_enum,
                classic: ucf.classic === "t" ? _I18n.default.t("wow.classic") : false
              };
            } else {
              t.character = {
                name: (0, _utilities.formatUsername)(t.username)
              };
            }
          });
        }

        // Customize posts
        api.changeWidgetSetting("post-menu", "collapseButtons", false);
        api.changeWidgetSetting("post-menu", "buttonType", "button");
        api.changeWidgetSetting("post-menu", "showReplyTitleOnMobile", true);
        api.changeWidgetSetting("poster-name", "hideNotes", true);
        api.changeWidgetSetting("post-avatar", "showNotes", true);
        api.changeWidgetSetting("post-avatar", "size", "huge");
        api.decorateWidget("post-meta-data:after", helper => {
          let attrs = helper.attrs;
          return helper.attach("b-post-statuses", attrs);
        });
        api.includePostAttributes("created_ip_address");
        api.includePostAttributes("battletag");
        api.includePostAttributes("user_post_count");

        // Show character instead of poster name
        if (siteSettings.selectable_characters_enabled) {
          api.reopenWidget("poster-name", {
            html(attrs) {
              return this.attach("character-details", attrs);
            }
          });
        }

        // Show post count in topic
        api.decorateWidget("poster-name:after-name", helper => {
          return helper.attach("user-post-count", helper.attrs);
        });

        // On blizzard forums, only Blizzard Employees can edit their names
        api.modifyClass("controller:preferences/account", {
          pluginId: "blizzard",
          canEditName: (0, _computed.alias)("model.blizzard_employee"),
          canSaveUser: (0, _computed.alias)("model.blizzard_employee")
        });

        // blizzard doesn't want to show the group popup. We do this by overriding
        // the code that wires up the card but not calling super.
        api.modifyClass("component:group-card-contents", {
          pluginId: "blizzard",
          _show() {}
        });
        if (siteSettings.blizzard_enable_dsa_prompt) {
          var _obj4;
          api.addPostMenuButton("assign", post => {
            if (this.currentUser || post.staff || post.canFlag) {
              return null;
            }
            return {
              action: "anonReport",
              icon: "flag",
              className: "anon-report",
              title: "blizzard.report_content",
              position: "second-last-hidden"
            };
          });
          api.attachWidgetAction("post", "anonReport", function () {
            modal.show(_anonymousReport.default, {
              model: {
                flagModel: this.model
              }
            });
          });
          api.modifyClass("component:modal/flag", (_obj4 = {
            get flagsAvailable() {
              let dsaFlag = {
                id: 1004,
                description: _I18n.default.t("blizzard.report_content.description"),
                is_flag: true,
                is_custom_flag: false,
                is_dsa: true,
                name: _I18n.default.t("blizzard.report_content.title"),
                name_key: "dsa_report"
              };
              return this.args.model.flagTarget.flagsAvailable(this).concat([dsaFlag]);
            },
            createFlag() {
              let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
              if (this.selected.is_dsa) {
                let url = (0, _getUrl.getAbsoluteURL)(`/dsa/report.json?post_id=${this.args.model.flagModel.id}`);
                window.open(url, "_blank", "noopener=true");
                this.args.closeModal();
              }
              if (this.selected.is_custom_flag) {
                opts.message = this.message;
              }
              this.args.model.flagTarget.create(this, opts);
            }
          }, (_applyDecoratedDescriptor(_obj4, "createFlag", [_object.action], Object.getOwnPropertyDescriptor(_obj4, "createFlag"), _obj4)), _obj4));
        }
      });
    }
  };
});