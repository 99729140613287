define("discourse/plugins/discourse-blizzard-plugin/initializers/blizzard-icons", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "blizzard-icons",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.11", api => {
        api.replaceIcon("caret-right", "blizzard-chevron_right");
        api.replaceIcon("caret-down", "blizzard-chevron_down");
        api.replaceIcon("caret-up", "blizzard-chevron_up");
        api.replaceIcon("d-regular", "blizzard-bell");
        api.replaceIcon("d-tracking", "blizzard-paw");
        api.replaceIcon("d-muted", "far-eye-slash");
        api.replaceIcon("d-watching", "far-eye");
        api.replaceIcon("d-watching-first", "blizzard-news");
        api.replaceIcon("d-drop-collapsed", "blizzard-chevron_down");
        api.replaceIcon("d-drop-expanded", "blizzard-chevron_up");
        api.replaceIcon("d-unliked", "far-heart");
        api.replaceIcon("filter", "blizzard-filter");
        api.replaceIcon("reply", "blizzard-reply-all");
        api.replaceIcon("thumb-tack", "blizzard-pin");
        api.replaceIcon("thumb-tack unpinned", "blizzard-pin");
        api.replaceIcon("user", "blizzard-char");
        api.replaceIcon("search", "blizzard-search");
        api.replaceIcon("bars", "blizzard-dot_menu");
        api.replaceIcon("arrow-circle-up", "blizzard-icon-small");
        api.replaceIcon("crown", "blizzard-icon-small");
        api.replaceIcon("blizzard", "blizzard-icon-small");
      });
    }
  };
});