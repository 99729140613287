define("discourse/plugins/discourse-blizzard-plugin/discourse/helpers/b-category-icon", ["exports", "discourse-common/lib/get-url", "discourse/plugins/discourse-blizzard-plugin/lib/blizzard-categories"], function (_exports, _getUrl, _blizzardCategories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = categoryIconPath;
  function categoryIconPath(category) {
    if (category) {
      // try URL first - get icon from theme
      let url = (0, _blizzardCategories.getMetaData)(category.get("id"), "icon_url");
      if (url) {
        return url;
      }
      let icon = (0, _blizzardCategories.getMetaData)(category.get("id"), "icon");
      return (0, _getUrl.getURLWithCDN)(`/plugins/discourse-blizzard-plugin/images/icons/${icon}.png?1530125097`);
    }
  }
});