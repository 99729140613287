define("discourse/plugins/discourse-blizzard-plugin/discourse/components/b-nav-breadcrumbs", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "discourse-common/lib/get-url", "@ember/template-factory"], function (_exports, _component, _component2, _service, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="b-breadcrumbs">
    <a href={{this.homeUrl}} class="b-breadcrumb b-breadcrumb-home">
      {{d-icon "blizzard-home"}}
      {{i18n "blizzard.forums"}}
    </a>
    {{#if this.category}}
      {{#if this.category.parentCategory}}
        <a
          href={{this.parentCategoryUrl}}
          class="b-breadcrumb b-breadcrumb-category"
        >
          {{d-icon "blizzard-chevron_right"}}
          {{this.category.parentCategory.name}}
        </a>
      {{/if}}
      <a href={{this.categoryUrl}} class="b-breadcrumb b-breadcrumb-category">
        {{d-icon "blizzard-chevron_right"}}
        {{this.category.name}}
      </a>
    {{/if}}
    {{#if this.topicTitle}}
      <a href={{this.topicUrl}} class="b-breadcrumb b-breadcrumb-topic">
        {{d-icon "blizzard-chevron_right"}}
        {{html-safe this.topicTitle}}
      </a>
    {{/if}}
  </div>
  */
  {
    "id": "YS74pkrX",
    "block": "[[[10,0],[14,0,\"b-breadcrumbs\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"homeUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-home\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"blizzard-home\"],null]],[1,\"\\n    \"],[1,[28,[35,1],[\"blizzard.forums\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"category\"]],[[[41,[30,0,[\"category\",\"parentCategory\"]],[[[1,\"      \"],[10,3],[15,6,[30,0,[\"parentCategoryUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-category\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"blizzard-chevron_right\"],null]],[1,\"\\n        \"],[1,[30,0,[\"category\",\"parentCategory\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,3],[15,6,[30,0,[\"categoryUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-category\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"blizzard-chevron_right\"],null]],[1,\"\\n      \"],[1,[30,0,[\"category\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"topicTitle\"]],[[[1,\"    \"],[10,3],[15,6,[30,0,[\"topicUrl\"]]],[14,0,\"b-breadcrumb b-breadcrumb-topic\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"blizzard-chevron_right\"],null]],[1,\"\\n      \"],[1,[28,[35,3],[[30,0,[\"topicTitle\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"d-icon\",\"i18n\",\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/b-nav-breadcrumbs.hbs",
    "isStrictMode": false
  });
  let BNavBreadcrumbs = _exports.default = (_class = class BNavBreadcrumbs extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "discovery", _descriptor2, this);
    }
    get category() {
      // The following fetches the current category. Discourse doesn't normally
      // make this available in the header so it involves a little peeking around
      // to find the right context.
      let currentCategory = null;
      if (this.router.currentRouteName.match(/^discovery\.[a-z]*category$/i)) {
        currentCategory = this.discovery.category;
      }
      if (this.args.topic) {
        return this.args.topic.get("category");
      } else if (currentCategory && !currentCategory.get("isUncategorizedCategory")) {
        return currentCategory;
      }
    }
    get homeUrl() {
      return (0, _getUrl.default)("/");
    }
    get topicTitle() {
      return this.args.topic?.get("fancyTitle") || "";
    }
    get topicUrl() {
      return this.args.topic?.get("url");
    }
    get categoryUrl() {
      return this.category?.get("url");
    }
    get parentCategoryUrl() {
      return this.category?.parentCategory?.get("url");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "discovery", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BNavBreadcrumbs);
});