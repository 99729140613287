define("discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/blizzard-add-user-note", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _ajaxError, _decorators, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.modalIsVisible}}
    <DModal
      @title={{i18n "user_notes.title"}}
      @subtitle={{i18n "user_notes.subtitle"}}
      @closeModal={{fn (mut this.modalIsVisible) false}}
      class="add-user-note-modal"
    >
      <Textarea @value={{newNote}} />
      <DButton
        @action={{action "attachNote"}}
        @label="user_notes.attach"
        @disabled={{this.attachDisabled}}
        class="btn-primary"
      />
    </DModal>
  {{/if}}
  */
  {
    "id": "b6UQh9Mk",
    "block": "[[[41,[30,0,[\"modalIsVisible\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"add-user-note-modal\"]],[[\"@title\",\"@subtitle\",\"@closeModal\"],[[28,[37,2],[\"user_notes.title\"],null],[28,[37,2],[\"user_notes.subtitle\"],null],[28,[37,3],[[28,[37,4],[[30,0,[\"modalIsVisible\"]]],null],false],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@value\"],[[30,0,[\"newNote\"]]]],null],[1,\"    \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[28,[37,7],[[30,0],\"attachNote\"],null],\"user_notes.attach\",[30,0,[\"attachDisabled\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `newNote` property path was used in the `discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/blizzard-add-user-note.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.newNote}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-modal\",\"i18n\",\"fn\",\"mut\",\"textarea\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/blizzard-add-user-note.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  let BlizzardAddUserNote = _exports.default = (_dec = (0, _decorators.default)("newNote", "saving"), (_class = class BlizzardAddUserNote extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "newNote", _descriptor, this);
      _initializerDefineProperty(this, "modalIsVisible", _descriptor2, this);
    }
    reset() {
      this.newNote = "";
      this.saving = false;
      this.modalIsVisible = true;
    }
    attachDisabled(newNote, saving) {
      return saving || !newNote || newNote.length === 0;
    }
    attachNote() {
      const note = this.store.createRecord("user-note");
      this.set("saving", true);
      let args = {
        raw: this.newNote,
        user_id: this.model.user.id
      };
      note.save(args).then(() => {
        this.set("newNote", "");
        if (this.model.callback) {
          this.model.callback();
        }
        this.modalIsVisible = false;
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("saving", false));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "newNote", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalIsVisible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "attachDisabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "attachDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachNote", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "attachNote"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BlizzardAddUserNote);
});